<template>
	<div class="login" v-if="$store.state.settingsInfo.loginPage === 1">
		<div class="logo">
			<img :src="$store.state.settingsInfo.loginLogo" alt=""/>
				<!-- logo -->
				<!-- <img src="../assets/home/logo.png" alt=""/> -->
			</div>
		<div class="content">
			<div class="container">
				<div class="topName">
						<span>心理测评系统</span>
					</div>
				<div class="tableContent">
					<div class="tabs">
						<el-tabs v-model="activeName" @tab-click="handleClick">
							<el-tab-pane label="手机号" name="telephoneFirst" v-if="verificationInfo.telephone !== ''">
								<!-- 手机号区域 -->
								<div class="telephoneContent" v-loading="loading">
									<el-form v-show="!telephoneStatus" :model="telephoneForm" :rules="telephoneRules"
										ref="telephoneRuleForm" class="demo-ruleForm">
										<el-form-item prop="telephone">
											<el-input v-model="telephoneForm.telephone"
												prefix-icon="el-icon-mobile-phone" placeholder="手机号" type="number"
												show-telephone>
											</el-input>
										</el-form-item>
										<el-form-item prop="Code">
											<div class="codeContent">
												<el-input v-model="telephoneForm.code" prefix-icon="el-icon-key"
													placeholder="验证码" show-telephone></el-input>
												<div class="code">
													<span v-if="!codeStatus" class="noCode"
														@click="getCode">获取验证码</span>
													<span v-else class="hasCode">{{ totalTime }}s 后重试</span>
													<!-- <span>获取验证码</span> -->
												</div>
											</div>
										</el-form-item>
										<el-form-item prop="password">
											<el-input v-model="telephoneForm.password" prefix-icon="el-icon-lock"
												placeholder="新密码" show-password></el-input>
										</el-form-item>
										<el-form-item prop="checkPass">
											<el-input v-model="telephoneForm.checkPass" prefix-icon="el-icon-lock"
												placeholder="确认密码" show-password></el-input>
										</el-form-item>
										<el-form-item prop="name">
											<el-button type="primary" class="first"
												@click="submitForm('telephoneRuleForm')">
												确认
											</el-button>
										</el-form-item>
									</el-form>
									<div v-show="telephoneStatus" class="telephoneStatus">
										<div class="img">
											<img src="@/assets/home/success.png" alt="">
											<span class="font">重置成功</span>
											<el-button type="primary" @click="toLogin">
												返回登录
											</el-button>
										</div>

									</div>
								</div>
							</el-tab-pane>
							<el-tab-pane label="密保问题" name="secretSecond"
								v-if="verificationInfo.userSecurities.length > 0">
								<div class="secretContent" v-loading="pwdLoading">
									<el-form :model="ruleForm" v-show="!secretStatus" :rules="rules" ref="ruleForm"
										class="demo-ruleForm">
										<!-- <el-form-item prop="nickname">
											<el-input v-model="ruleForm.nickname" prefix-icon="el-icon-user"
												placeholder="登录名">
											</el-input>
										</el-form-item> -->
										<el-form-item prop="userSecurityId">
											<el-select v-model="ruleForm.userSecurityId" placeholder="密保问题"
												style="width:100%">
												<i slot="prefix" class="el-input__icon el-icon-question" />
												<el-option v-for="item in showList" :key="item.id" :label="item.label" :value="item.id">
												</el-option>
											</el-select>
											<!-- <el-input v-model="ruleForm.name" prefix-icon="el-icon-question"
												placeholder="密保问题">
											</el-input> -->
										</el-form-item>
										<el-form-item prop="answer">
											<el-input v-model="ruleForm.answer" type="text" prefix-icon="el-icon-question"
												placeholder="答案">
											</el-input>
										</el-form-item>
										<el-form-item prop="password">
											<el-input v-model="ruleForm.password" prefix-icon="el-icon-lock"
												placeholder="新密码" show-password></el-input>
										</el-form-item>
										<el-form-item prop="checkPass">
											<el-input v-model="ruleForm.checkPass" prefix-icon="el-icon-lock"
												autocomplete="off" placeholder="确认密码" show-password></el-input>
										</el-form-item>
										<el-form-item>
											<el-button type="primary" @click="sureSecret('ruleForm')" class="back">确认
											</el-button>
										</el-form-item>
									</el-form>
									<div v-show="secretStatus" class="telephoneStatus">
										<div class="img">
											<img src="@/assets/home/success.png" alt="">
											<span class="font">重置成功</span>
											<el-button type="primary" @click="toLogin">
												返回登录
											</el-button>
										</div>

									</div>
								</div>
							</el-tab-pane>
						</el-tabs>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="login2" v-else>
		<div class="content">
			
			<div class="logo">
				
				<!-- logo -->
				<img :src="$store.state.settingsInfo.loginLogo" alt=""/>
			</div>
			<div class="container">
				<div class="tableContent">
					<div class="tabs">
						<el-tabs v-model="activeName" @tab-click="handleClick">
							<el-tab-pane label="手机号" name="telephoneFirst" v-if="verificationInfo.telephone !== ''">
								<!-- 手机号区域 -->
								<div class="telephoneContent" v-loading="loading">
									<el-form v-show="!telephoneStatus" :model="telephoneForm" :rules="telephoneRules"
										ref="telephoneRuleForm" class="demo-ruleForm">
										<el-form-item prop="telephone">
											<el-input v-model="telephoneForm.telephone"
												prefix-icon="el-icon-mobile-phone" placeholder="手机号" type="number"
												show-telephone>
											</el-input>
										</el-form-item>
										<el-form-item prop="Code">
											<div class="codeContent">
												<el-input v-model="telephoneForm.code" prefix-icon="el-icon-key"
													placeholder="验证码" show-telephone></el-input>
												<div class="code">
													<span v-if="!codeStatus" class="noCode"
														@click="getCode">获取验证码</span>
													<span v-else class="hasCode">{{ totalTime }}s 后重试</span>
													<!-- <span>获取验证码</span> -->
												</div>
											</div>
										</el-form-item>
										<el-form-item prop="password">
											<el-input v-model="telephoneForm.password" prefix-icon="el-icon-lock"
												placeholder="新密码" show-password></el-input>
										</el-form-item>
										<el-form-item prop="checkPass">
											<el-input v-model="telephoneForm.checkPass" prefix-icon="el-icon-lock"
												placeholder="确认密码" show-password></el-input>
										</el-form-item>
										<el-form-item prop="name">
											<el-button type="primary" class="first"
												@click="submitForm('telephoneRuleForm')">
												确认
											</el-button>
										</el-form-item>
									</el-form>
									<div v-show="telephoneStatus" class="telephoneStatus">
										<div class="img">
											<img src="@/assets/home/success.png" alt="">
											<span class="font">重置成功</span>
											<el-button type="primary" @click="toLogin">
												返回登录
											</el-button>
										</div>

									</div>
								</div>
							</el-tab-pane>
							<el-tab-pane label="密保问题" name="secretSecond"
								v-if="verificationInfo.userSecurities.length > 0">
								<div class="secretContent" v-loading="pwdLoading">
									<el-form :model="ruleForm" v-show="!secretStatus" :rules="rules" ref="ruleForm"
										class="demo-ruleForm">
										<!-- <el-form-item prop="nickname">
											<el-input v-model="ruleForm.nickname" prefix-icon="el-icon-user"
												placeholder="登录名">
											</el-input>
										</el-form-item> -->
										<el-form-item prop="userSecurityId">
											<el-select v-model="ruleForm.userSecurityId" placeholder="密保问题"
												style="width:100%">
												<i slot="prefix" class="el-input__icon el-icon-question" />
												<el-option v-for="item in showList" :key="item.id" :label="item.label" :value="item.id">
												</el-option>
											</el-select>
											<!-- <el-input v-model="ruleForm.name" prefix-icon="el-icon-question"
												placeholder="密保问题">
											</el-input> -->
										</el-form-item>
										<el-form-item prop="answer">
											<el-input v-model="ruleForm.answer" type="text" prefix-icon="el-icon-question"
												placeholder="答案">
											</el-input>
										</el-form-item>
										<el-form-item prop="password">
											<el-input v-model="ruleForm.password" prefix-icon="el-icon-lock"
												placeholder="新密码" show-password></el-input>
										</el-form-item>
										<el-form-item prop="checkPass">
											<el-input v-model="ruleForm.checkPass" prefix-icon="el-icon-lock"
												autocomplete="off" placeholder="确认密码" show-password></el-input>
										</el-form-item>
										<el-form-item>
											<el-button type="primary" @click="sureSecret('ruleForm')" class="back">确认
											</el-button>
										</el-form-item>
									</el-form>
									<div v-show="secretStatus" class="telephoneStatus">
										<div class="img">
											<img src="@/assets/home/success.png" alt="">
											<span class="font">重置成功</span>
											<el-button type="primary" @click="toLogin">
												返回登录
											</el-button>
										</div>

									</div>
								</div>
							</el-tab-pane>
						</el-tabs>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapActions} from 'vuex'
import { getCodes, updatePwd } from '@/api/forgetPwd'
export default {
	data() {
		// 手机号密码验证
		var validatePass = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入密码'));
			} else {
				if (this.telephoneForm.checkPass !== '') {
					this.$refs.telephoneRuleForm.validateField('checkPass');
				}
				callback();
			}
		};
		var validatePass2 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'));
			} else if (value !== this.telephoneForm.password) {
				callback(new Error('密码输入不一致，请检查并重新输入!'));
			} else {
				callback();
			}
		};
		// 密保密码验证
		var Pass3 = (rule, value, callback) => {
			console.log(value)
			if (value === '') {
				callback(new Error('请输入密码'));
			} else {
				if (this.ruleForm.checkPass !== '') {
					this.$refs.ruleForm.validateField('checkPass');
				}
				callback();
			}
		};
		var Pass4 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'));
			} else if (value !== this.ruleForm.password) {
				callback(new Error('密码输入不一致，请检查并重新输入!'));
			} else {
				callback();
			}
		};
		return {
			loginPage: 2,
			activeName: 'telephoneFirst', // 当前激活的tab
			// 密保表单提交规则
			ruleForm: {
				// nickname: null,
				password: null,
				checkPass: null,
				userSecurityId: null,
				answer: ''
			},
			userSecurities: [], // 密保问题数组
			showList: [],
			// 手机号表单提交信息
			telephoneForm: {
				telephone: null, // 电话
				password: null, // 密码
				code: null, // 验证码
				checkPass: null // 确认密码
			},
            optionList: [{ problem: 1, label: '您父亲的名字？' }, { problem: 2, label: '您母亲的名字？' }, { problem: 3, label: '您汽车的品牌？' }, { problem: 4, label: '您手机的品牌？' }, { problem: 5, label: '您喜欢的颜色？' }, { problem: 6, label: '您最喜欢吃的水果？' }, { problem: 7, label: '您的家乡在哪里？' }, { problem: 8, label: '您最好的伙伴是谁？' }],
			// 密保验证规则
			rules: {
				password: [
					{ validator: Pass3, trigger: 'blur' },
					{ required: true, message: '请输入新密码！', trigger: 'blur' }
				],
				checkPass: [
					{ validator: Pass4, trigger: 'change' },
					{ required: true, message: '请再次输入密码！', trigger: 'blur' }
				],
				nickname: [
					{ required: true, message: '请输入姓名！', trigger: 'blur' }
				],
				answer: [
					{ required: true, message: '请输入答案！', trigger: 'blur' }
				],
				userSecurityId: [
					{ required: true, message: '请选择密保问题！', trigger: 'change' }
				]
			},
			// 手机号验证规则
			telephoneRules: {
				password: [
					{ validator: validatePass, trigger: 'blur' },
					{ required: true, message: '请输入新密码！', trigger: 'blur' }
				],
				checkPass: [
					{ validator: validatePass2, trigger: 'change' },
					{ required: true, message: '请再次输入密码！', trigger: 'blur' }
				],
				nickname: [
					{ required: true, message: '请输入姓名！', trigger: 'blur' }
				],
				username: [
					{ required: true, message: '请输入登录名！', trigger: 'blur' }
				],
				telephone: [
					{ required: true, message: '请输入手机号！', trigger: 'blur' }
				],
				code: [
					{ required: true, message: '请输入验证码！', trigger: 'blur' }
				],
			},
			telephoneStatus: false, // 用于保存手机号是否验证通过
			secretStatus: false, // 用于保存密保是否验证通过
			verificationInfo: null, // 验证返回的信息
			codeStatus: false, // 判断用户是否点击了获取验证码
			totalTime: 60, // 验证码的时长
			loading: false, // 手机加载动画
			pwdLoading: false // 密保加载动画
		};
	},
	methods: {
		...mapActions(['getInfo']),
		handleClick(tab, event) {
			console.log(tab, event);
		},
		// 获取注册验证码
		async getCode() {
			// console.log(this.RegisterForm.telephone);

			if (this.telephoneForm.telephone !== null && this.telephoneForm.telephone !== '') {
				this.codeStatus = true;
				let clock = window.setInterval(() => {
					this.totalTime--;
					if (this.totalTime < 0) {
						//当倒计时小于0时清除定时器
						window.clearInterval(clock); //关
						this.totalTime = 60;
						this.codeStatus = false;
					}
				}, 1000);
				const res = await getCodes({ telephone: this.telephoneForm.telephone });
				console.log(res);
			} else {
				this.$message({
					showClose: true,
					message: '请输入手机号！'
				});
			}
		},
		// 返回登录
		toLogin() {
			this.$router.push('/');
		},
		// 手机号确认
		submitForm(formName) {
			this.$refs[formName].validate(async (valid) => {
				if (valid) {
					this.loading = true;
					// this.telephoneStatus = true;
					// console.log(this.telephoneForm);
					let info = this.telephoneForm;
					delete info.checkPass;
					info.type = 1;
					info.id = this.verificationInfo.id;
					console.log(info);
					setTimeout(() => {
						this.loading = false;
					}, 500);
					const res = await updatePwd(JSON.stringify(info));
					console.log(res);
					if (res.code === 200) {
						this.telephoneStatus = true;
					} else {
						this.$message({
							showClose: true,
							message: '重置失败！' + res.msg,
							type: 'error'
						});
					}
					// alert('submit!');
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		// 密保确认
		// sureSecret() {
		// 	this.secretStatus = true;
		// }
		sureSecret(formName) {
			this.$refs[formName].validate( async(valid) => {
				if (valid) {
					console.log(this.verificationInfo)
					this.pwdLoading = true;
					let info = JSON.parse(JSON.stringify(this.ruleForm));
					// info.answer = Number(info.answer)
					delete info.checkPass;
					info.type = 2;
					info.id = this.verificationInfo.id;
					console.log(info)
					const res = await updatePwd(JSON.stringify(info));
					console.log(res);
					if (res.code === 200) {
						this.secretStatus = true;
					} else {
						this.$message({
							showClose: true,
							message: '重置失败！' + res.msg,
							type: 'error'
						});
					}
					setTimeout(() => {
						this.pwdLoading = false;
					}, 500);
					// alert('submit!');
					// 验证通过
					// this.secretStatus = true;
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		// 	submitForm(formName) {
		//     this.$refs[formName].validate((valid) => {
		//       if (valid) {
		//         alert('submit!');
		//       } else {
		//         console.log('error submit!!');
		//         return false;
		//       }
		//     });
		//   },
	},
	created() {
		this.getInfo();
		this.verificationInfo = JSON.parse(localStorage.getItem('getPwd'));
		if (this.verificationInfo.telephone !== '') {
			this.activeName = 'telephoneFirst';
			this.telephoneForm.telephone = this.verificationInfo.telephone;
		} else {
			this.activeName = 'secretSecond';
		}
		// 筛选出用户选择的密保问题数组
		if (this.verificationInfo.userSecurities.length > 0) {
			this.userSecurities = this.verificationInfo.userSecurities;
			let list = []
			this.optionList.forEach(item => {
				this.userSecurities.forEach(ele => {
					if(item.problem === ele.problem) {
						list.push({...item, id: ele.id});
					}
				})
			})
			console.log(list)
			this.showList = list;
		}
		// console.log(JSON.parse(localStorage.getItem('getPwd')))
	}
}
</script>

<style lang="scss" scoped>
.login {
	.topName{
// 		position: absolute;
// 		// text-align: center;
// 		// width: 440px;
// 		// top:60px;
		font-size: 32px;
		font-weight: 600;
		color: var(--custom-color);
// 		display: flex;
// 		justify-content: center;
	}
	.hasCode {
		color: #7d7d7d;
	}

	.noCode {
		color: var(--custom-color);
	}
}
.login2 {
	.logo{
	img{
		width: 60%;
		// height: 80%;
	}
	display: flex;
	justify-content: center;
	align-items: center;
}
	.hasCode {
		color: #7d7d7d;
	}

	.noCode {
		color: var(--custom-color);
	}
}
@media screen and (min-width: 751PX) {
	
	.login {
		// background-image: url('../assets/home/backgroundImage.png');
		background-color: var(--theme-color);
		background-repeat: no-repeat;
		background-position: center;
		background-attachment: fixed;
		background-size: cover;
		// background-color: antiquewhite;
		height: 100vh;
		width: 100vw;
		overflow: hidden;
		.topName{
		position: absolute;
		// text-align: center;
		width: 440px;
		top:60px;
		font-size: 32px;
		font-weight: 600;
		color: var(--custom-color);
		display: flex;
		justify-content: center;
	}
		.logo{
		// position: fixed;
		padding-top: 50px;
		padding-left: 60px;
		margin-bottom: 20px;
		img{
			height: 60px;
		}
	
	}

		.content {
			position: relative;
			display: flex;
		justify-content: center;

			// .logo {
			// 	z-index: 2;
			// 	text-align: center;
			// 	line-height: 160px;
			// 	position: absolute;
			// 	top: -80px;
			// 	left: calc(650px - 50% - 80px);
			// 	;
			// 	width: 160px;
			// 	height: 160px;
			// 	background: #FFFFFF;
			// 	border-radius: 50%;
			// 	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3600);

			// }

			.container {
				width: 650px;
				// height: 614px;
				background-color: rgba(255, 255, 255, 0.5);
				border-radius: 12px;
				// opacity: 0.5;
				filter: blur(0px);
				padding: 50px 55px;
				padding-top: 120px;
				box-sizing: border-box;

				.tableContent {
					.tabs {

						.el-button {
							width: 100%;
							// font-size: 16px;
							border-radius: 4px;
							box-sizing: border-box;
						}

						.el-form-item {
							&:last-child {
								margin-bottom: 10px;
							}
						}


					}

					.telephoneContent {
						.el-button {
							margin-top: 20px;

						}

						.codeContent {
							position: relative;

							.code {
								position: absolute;
								top: 0;
								right: 0px;
								display: flex;
								height: 100%;
								align-items: center;
								// height: 100%;
								// width: 100px;
								// border-left: 1px solid #E6E6E6;
								// text-align: center;
								// line-height: 45px;

								span {
									display: inline-block;
									line-height: 26px;
									width: 90px;
									height: 26px;
									border-left: 1px solid #E6E6E6;
									font-size: 14px;
									font-weight: 400;
									// color: var(--custom-color);
									padding-left: 14px;

									&:hover {
										cursor: pointer;
									}
								}
							}
						}



					}

					.telephoneStatus {
						padding-top: 50px;

						.img {
							text-align: center;

							img {
								height: 100px;
								width: 100px;
							}

							.font {
								display: block;
								margin-top: 10px;
								font-size: 14px;
								font-weight: 400;
								color: #303030;
								margin-bottom: 66px;
							}
						}

					}

					.secretContent {
						.back {
							margin-top: 20px;
						}
					}
				}
			}
		}
	}
	.login2 {
		// background-image: url('../assets/home/backgroundImage.png');
		background-color: var(--theme-color);
		background-repeat: no-repeat;
		background-position: center;
		background-attachment: fixed;
		background-size: cover;
		// background-color: antiquewhite;
		height: 100vh;
		width: 100vw;
		overflow: hidden;
		display: flex;
		// align-items: center;
		 padding-top: 100px;
		justify-content: center;

		.content {
			position: relative;

			.logo {
				z-index: 2;
				text-align: center;
				line-height: 160px;
				position: absolute;
				top: -80px;
				left: calc(650px - 50% - 80px);
				;
				width: 160px;
				height: 160px;
				background: #FFFFFF;
				border-radius: 50%;
				box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3600);

			}

			.container {
				width: 650px;
				// height: 614px;
				background-color: rgba(255, 255, 255, 0.5);
				border-radius: 12px;
				// opacity: 0.5;
				filter: blur(0px);
				padding: 50px 55px;
				padding-top: 120px;
				box-sizing: border-box;

				.tableContent {
					.tabs {

						.el-button {
							width: 100%;
							// font-size: 16px;
							border-radius: 4px;
							box-sizing: border-box;
						}

						.el-form-item {
							&:last-child {
								margin-bottom: 10px;
							}
						}


					}

					.telephoneContent {
						.el-button {
							margin-top: 20px;

						}

						.codeContent {
							position: relative;

							.code {
								position: absolute;
								top: 0;
								right: 0px;
								display: flex;
								height: 100%;
								align-items: center;
								// height: 100%;
								// width: 100px;
								// border-left: 1px solid #E6E6E6;
								// text-align: center;
								// line-height: 45px;

								span {
									display: inline-block;
									line-height: 26px;
									width: 90px;
									height: 26px;
									border-left: 1px solid #E6E6E6;
									font-size: 14px;
									font-weight: 400;
									// color: var(--custom-color);
									padding-left: 14px;

									&:hover {
										cursor: pointer;
									}
								}
							}
						}



					}

					.telephoneStatus {
						padding-top: 50px;

						.img {
							text-align: center;

							img {
								height: 100px;
								width: 100px;
							}

							.font {
								display: block;
								margin-top: 10px;
								font-size: 14px;
								font-weight: 400;
								color: #303030;
								margin-bottom: 66px;
							}
						}

					}

					.secretContent {
						.back {
							margin-top: 20px;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 750PX) {
	
	.login {
		// background-image: url('../assets/home/backgroundImage.png');
		background-color: var(--theme-color);
		background-repeat: no-repeat;
		background-position: center;
		background-attachment: fixed;
		background-size: cover;
		// background-color: antiquewhite;
		height: 100vh;
		width: 100vw;
		overflow: hidden;
		.topName{
		margin-bottom: 40px;
		font-size: 20px;
		text-align: center;
	}
	.logo{
		// position: fixed;
		padding-top: 20px;
		padding-left: 20px;
		margin-bottom: 40px;
		img{
			height: 30px;
		}
	}

		.content {
			// position: relative;
			// display: flex;
			// justify-content: center;
			// position: relative;
			width: 100%;

			// .logo {

			// 	z-index: 2;
			// 	margin: auto;
			// 	margin-bottom: 10px;
			// 	text-align: center;
			// 	line-height: 120px;
			// 	// position: absolute;
			// 	// top: -120px;
			// 	// left: calc(650px - 50% - 80px);
			// 	// ;
			// 	width: 120px;
			// 	height: 120px;
			// 	background: #FFFFFF;
			// 	border-radius: 50%;
			// 	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3600);

			// }

			.container {
				width: 96%;
				margin: auto;
				// position: relative;
				// height: 614px;
				// background-color: rgba(255, 255, 255, 0.5);
				// border-radius: 12px;
				// // opacity: 0.5;
				// filter: blur(0px);
				// padding: 50px 55px;
				// padding-top: 120px;
				// box-sizing: border-box;

				.tableContent {
					.tabs {

						.el-button {
							width: 100%;
							// font-size: 16px;
							border-radius: 4px;
							box-sizing: border-box;
						}

						.el-form-item {
							&:last-child {
								margin-bottom: 10px;
							}
						}


					}

					.telephoneContent {
						.el-button {
							margin-top: 20px;

						}

						.codeContent {
							position: relative;

							.code {
								position: absolute;
								top: 0;
								right: 0px;
								display: flex;
								height: 100%;
								align-items: center;
								// height: 100%;
								// width: 100px;
								// border-left: 1px solid #E6E6E6;
								// text-align: center;
								// line-height: 45px;

								span {
									display: inline-block;
									line-height: 26px;
									width: 90px;
									height: 26px;
									border-left: 1px solid #E6E6E6;
									font-size: 14px;
									font-weight: 400;
									// color: var(--custom-color);
									padding-left: 14px;

									&:hover {
										cursor: pointer;
									}
								}
							}
						}



					}

					.telephoneStatus {
						padding-top: 50px;

						.img {
							text-align: center;

							img {
								height: 100px;
								width: 100px;
							}

							.font {
								display: block;
								margin-top: 10px;
								font-size: 14px;
								font-weight: 400;
								color: #303030;
								margin-bottom: 66px;
							}
						}

					}

					.secretContent {
						.back {
							margin-top: 20px;
						}
					}
				}
			}
		}
	}
	.login2 {
		// background-image: url('../assets/home/backgroundImage.png');
		background-color: var(--theme-color);
		background-repeat: no-repeat;
		background-position: center;
		background-attachment: fixed;
		background-size: cover;
		// background-color: antiquewhite;
		height: 100vh;
		width: 100vw;
		overflow: hidden;
		display: flex;
		align-items: center;
		//  padding-top: 200px;
		justify-content: center;

		.content {
			// position: relative;
			// display: flex;
			// justify-content: center;
			// position: relative;
			width: 100%;

			.logo {

				z-index: 2;
				margin: auto;
				margin-bottom: 10px;
				text-align: center;
				line-height: 120px;
				// position: absolute;
				// top: -120px;
				// left: calc(650px - 50% - 80px);
				// ;
				width: 120px;
				height: 120px;
				background: #FFFFFF;
				border-radius: 50%;
				box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3600);

			}

			.container {
				width: 96%;
				margin: auto;
				// position: relative;
				// height: 614px;
				// background-color: rgba(255, 255, 255, 0.5);
				// border-radius: 12px;
				// // opacity: 0.5;
				// filter: blur(0px);
				// padding: 50px 55px;
				// padding-top: 120px;
				// box-sizing: border-box;

				.tableContent {
					.tabs {

						.el-button {
							width: 100%;
							// font-size: 16px;
							border-radius: 4px;
							box-sizing: border-box;
						}

						.el-form-item {
							&:last-child {
								margin-bottom: 10px;
							}
						}


					}

					.telephoneContent {
						.el-button {
							margin-top: 20px;

						}

						.codeContent {
							position: relative;

							.code {
								position: absolute;
								top: 0;
								right: 0px;
								display: flex;
								height: 100%;
								align-items: center;
								// height: 100%;
								// width: 100px;
								// border-left: 1px solid #E6E6E6;
								// text-align: center;
								// line-height: 45px;

								span {
									display: inline-block;
									line-height: 26px;
									width: 90px;
									height: 26px;
									border-left: 1px solid #E6E6E6;
									font-size: 14px;
									font-weight: 400;
									// color: var(--custom-color);
									padding-left: 14px;

									&:hover {
										cursor: pointer;
									}
								}
							}
						}



					}

					.telephoneStatus {
						padding-top: 50px;

						.img {
							text-align: center;

							img {
								height: 100px;
								width: 100px;
							}

							.font {
								display: block;
								margin-top: 10px;
								font-size: 14px;
								font-weight: 400;
								color: #303030;
								margin-bottom: 66px;
							}
						}

					}

					.secretContent {
						.back {
							margin-top: 20px;
						}
					}
				}
			}
		}
	}
}

</style>