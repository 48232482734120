// 忘记密码
import service from '@/utils/request'
// 验证用户
export function checkUser(params) {
  return service({
    url: '/user/checkUser',
    method: 'get',
    params
  });
}
// 忘记密码获取验证码
export function getCodes(params) {
  return service({
    url: '/user/getBackPassword/code',
    method: 'get',
    params
  });
}
// 找回密码
export function updatePwd(data) {
  return service({
    url: '/user/update/password',
    method: 'post',
    data
  });
}